<template>
  <div>
    <van-dialog
      cancel-button-text="取消"
      confirm-button-text="确认"
      v-model="show"
      title="创建成功"
    >
      <van-cell-group>
        <van-cell title="名称" :value="currentData.name" />
        <van-cell title="账号" :value="currentData.username" />
        <van-cell title="密码" :value="currentData.password" />
        <van-cell title="提现密码" :value="currentData.withdraw_password" />
      </van-cell-group>

      <van-button
        v-clipboard:copy="
          '名称' +
          ':' +
          currentData.name +
          '\r\n' +
          '账号' +
          ': ' +
          currentData.username +
          '\r\n' +
          '密码' +
          ': ' +
          currentData.password +
          '\r\n' +
          '提现密码' +
          ': ' +
          currentData.withdraw_password
        "
        v-clipboard:success="onCopy"
        style="margin-bottom: 15px"
        plain
        hairline
        type="info"
        block
        >复制</van-button
      >
    </van-dialog>
    <!-- top -->
    <van-sticky>
      <van-nav-bar class="topNavbar" title="注册" left-arrow>
        <template #left>
          <van-icon name="arrow-left" style="display: none" />
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div
      class="wrap"
      :style="{
        backgroundImage:
          'url(' +
          $returnColor().path +
          ($store.state.selfData.member_id
            ? '/home-page_bg.png'
            : '/home-page_bg.png') +
          ')',
      }"
    >
      <van-form :submit-on-enter="false" class="formWrap">
        <van-field
          required
          style="border-radius: 10px 10px 0 0"
          v-model="registerForm.username"
          name="账号"
          label="账号"
          placeholder="账号"
          :rules="[{ required: true, message: '请填写账号' }]"
        />
        <van-field
          required
          v-model="registerForm.name"
          name="名称"
          label="名称"
          placeholder="名称"
          :rules="[{ required: true, message: '请填写账号' }]"
        />
        <van-field
          required
          v-model="registerForm.password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
        />
        <van-field
          required
          v-model="registerForm.password_confirmation"
          type="password"
          name="确认密码"
          label="确认密码"
          placeholder="确认密码"
        />

        <van-field
          v-model="registerForm.captcha"
          style="border-radius: 0 0 10px 10px"
          required
          clearable
          label="验证码"
          placeholder="验证码"
        >
          <template #button>
            <img
              :src="captcha.img"
              alt=""
              class="validateImg"
              style="position: absolute; right: 0; top: -5px"
              @click="getCaptcha"
            />
          </template>
        </van-field>
        <div style="margin: 16px" class="submitBtn">
          <van-button
            :color="$returnColor().color3"
            style="width: 88px; height: 34px"
            @click="confirmRegister"
            type="info"
            native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      registerForm: {},
      show: false,
      currentData: {},
      captcha: {},
      captchaTimeOut: "",
      captchaTime: 30000,
    };
  },
  methods: {
    getCaptcha() {
      this.$http.get(`/mobile/captcha`).then((res) => {
        if (res.data.code == 0) {
          this.captcha = res.data.data;
          clearTimeout(this.captchaTimeOut);
          if (this.$route.name != "register") return;
          this.captchaTimeOut = setTimeout(() => {
            this.getCaptcha();
          }, this.captchaTime);
        }
      });
    },
    onCopy() {
      this.$toast.success("已复制");
    },
    confirmRegister() {
      if (!this.$route.query.code) {
        return;
      }
      if (!this.registerForm.username) {
        this.$dialog.confirm({
          confirmButtonText: "确认",
          title: "错误",
          message: "请输入账号",
          showCancelButton: false,
        });
        return;
      }
      if (!this.registerForm.name) {
        this.$dialog.confirm({
          confirmButtonText: "确认",
          title: "错误",
          message: "请输入姓名",
          showCancelButton: false,
        });
        return;
      }
      if (!this.registerForm.password) {
        this.$dialog.confirm({
          confirmButtonText: "确认",
          title: "错误",
          message: "请输入密码",
          showCancelButton: false,
        });
        return;
      }
      if (!this.registerForm.password_confirmation) {
        this.$dialog.confirm({
          confirmButtonText: "确认",
          title: "错误",
          message: "请输入密码",
          showCancelButton: false,
        });
        return;
      }
      let sendData = JSON.parse(JSON.stringify(this.registerForm));
      sendData.key = this.captcha.key;

      this.$http
        .post(`/mobile/register/${this.$route.query.code}`, sendData)
        .then((res) => {
          if (res.data.code == 0) {
            this.show = true;
            // this.$dialog
            //   .confirm({
            //     confirmButtonText: "前往登入",
            //     title: "注册成功",
            //     showCancelButton: false,
            //   })
            //   .then(() => {
            //     this.$router.push("/userLogin");
            //   });
          } else {
            this.registerForm.captcha = "";
          }
        });
    },
  },
  created() {
    this.getCaptcha();
  },

  destroyed() {
    clearTimeout(this.captchaTimeOut);
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  // background-image: url("/home-page_bg.png");
  background-position: top;
  background-size: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
.formWrap {
  width: 285px;
  border-radius: 10px;
}
.submitBtn {
  display: flex;
  justify-content: center;
  font-size: 16px;
}
</style>
